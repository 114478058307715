import { wasteDataT } from 'components/invoicing/invoice/Form/WasteData.vue';
import { BaseSchema, ModelType, type ToManyRelationship, type ToOneRelationship } from '~~/app/base/schemas/BaseSchema';

type InvoiceRelationships = {
    invoiceSections: ToManyRelationship<ModelType.INVOICE_SECTIONS>;
    contact: ToOneRelationship<ModelType.CONTACTS>;
    worksite: ToOneRelationship<ModelType.WORKSITES>;
    lead: ToOneRelationship<ModelType.LEADS>;
};

type InvoiceAttributes = {
    documentType: string;
    status: string;
    number: string | null;
    internalNumber: number | null;
    subtotal: number | null;
    totalTax: number | null;
    total: number | null;
    totalPaid: number | null;
    totalDue: number | null;
    pdfId: string | null;
    title: string | null;
    description: string | null;
    conditions: string | null;
    belgianStructuredCommunication: string;
    dueAt: string | null;
    sentAt: string | null;
    customCreatedAt: string | null;
    trueDate: string;
    archivedAt: string | null;
    contactState: Record<string, any> | null;
    energyAfterRenovation: string | null;
    inspectedAt: string | null;
    frenchVatCertificateUrl: string | null;
    wasteData: wasteDataT | null;
};

type InvoiceMeta = {
    permissions: {
        delete: boolean;
    };
    depositInvoicesPercentage?: number;
    worksiteProgressBillingsPercentage?: number;
};

export default class InvoiceSchema extends BaseSchema<InvoiceAttributes, InvoiceMeta, InvoiceRelationships> {
    protected type: ModelType = ModelType.INVOICES;
}
