<script setup lang="ts">
    import { wasteDataT } from '../WasteData.vue';
    import WasteCenter from '@/app/common/models/WasteCenter';
    import EndpointFactory from '@/app/factories/EndpointFactory';
    import { ModelType } from '@/app/base/schemas/BaseSchema';
    import InvoiceForm from '~~/app/invoicing/classes/InvoiceForm';

    const props = defineProps({
  invoiceForm: null
});

    const form = useForm<{
        wasteType: string;
        wasteCenter: WasteCenter | null;
        estimatedVolumeLow: number | null;
        estimatedVolumeHigh: number | null;
        cost: number | null;
    }>({
        wasteType: '',
        wasteCenter: null,
        estimatedVolumeHigh: null,
        estimatedVolumeLow: null,
        cost: null,
    });

    useModal('invoicing:invoice-form:waste-data', (data: { form: InvoiceForm }) => {
        form.reset();
        if (data.form.fields.wasteData) fillWasteAttributes(data.form.fields.wasteData);
    });

    const endpoint = EndpointFactory.make(ModelType.WASTE_CENTER);
    const fetchWasteCenter = async (wasteCenterId: string) => {
        const response = await endpoint.retrieve(wasteCenterId);
        if (response.error) return useToasteoError();
        return response.data;
    };

    const loading = ref(false);
    const fillWasteAttributes = async (wasteData: wasteDataT) => {
        loading.value = true;
        const wasteCenter = await fetchWasteCenter(wasteData?.wasteCenterId);
        form.set('wasteCenter', wasteCenter);
        form.set('wasteType', wasteData?.type);
        form.set('estimatedVolumeHigh', wasteData?.estimatedVolumeHigh);
        form.set('estimatedVolumeLow', wasteData?.estimatedVolumeLow);
        form.set('cost', wasteData?.cost);
        loading.value = false;
    };

    const reset = () => {
        props.invoiceForm.set('wasteData', null);
        useEvent('invoicing:invoice-form:waste-data:close');
    };

    const submit = () => {
        const wasteData = {
            wasteCenterId: form.get('wasteCenter').getId(),
            type: form.get('wasteType'),
            estimatedVolumeHigh: form.get('estimatedVolumeHigh'),
            estimatedVolumeLow: form.get('estimatedVolumeLow'),
            cost: form.get('cost'),
        };
        props.invoiceForm.set('wasteData', wasteData);
        useEvent('invoicing:invoice-form:waste-data:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice-form:waste-data"
    >
        <ModalLoader v-if="loading"></ModalLoader>
        <div v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ $t('invoicing.invoice_form.edit_waste_data_modal.title') }}
                </h2>
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <!--  IN PROGRESS -->
                    <FormWasteCenterSelect
                        :form="form"
                        input-name="wasteCenter"
                        :with-create-button="true"
                    />
                    <FormWasteTypeSelect
                        :form="form"
                        with-null-option
                        input-name="wasteType"
                    />
                    <FormTwoFields>
                        <template #field-1>
                            <FormInput
                                type="number"
                                :form="form"
                                input-type="number"
                                input-name="estimatedVolumeLow"
                                :min="0"
                                :step="0.01"
                            />
                        </template>
                        <template #field-2>
                            <FormInput
                                type="number"
                                input-type="number"
                                :form="form"
                                input-name="estimatedVolumeHigh"
                                :min="0"
                                :step="0.01"
                            />
                        </template>
                    </FormTwoFields>
                    <FormPrice
                        :form="form"
                        input-name="cost"
                    />
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :class-name="$theme('button.style.white')"
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            @clicked="reset"
                        >
                            {{ $t('actions.reset') }}
                        </LoadingButton>
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                        >
                            {{ $t('actions.save') }}
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
</template>
